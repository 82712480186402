import React from "react"

const StarRating = () => {
    return(
        <>
            <div className="flex items-center bg-[#065796] w-fit px-5 py-2 rounded-full *:px-1">
                <svg className="size-7" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0245 1.58156C16.1741 1.1209 16.8259 1.1209 16.9755 1.58156L20.0657 11.0922C20.2665 11.7102 20.8425 12.1287 21.4923 12.1287H31.4924C31.9768 12.1287 32.1781 12.7485 31.7863 13.0332L23.696 18.9111C23.1703 19.2931 22.9503 19.9701 23.1511 20.5881L26.2413 30.0988C26.391 30.5594 25.8638 30.9425 25.4719 30.6578L17.3817 24.7799C16.8559 24.3979 16.1441 24.3979 15.6183 24.7799L7.52808 30.6578C7.13623 30.9425 6.60899 30.5594 6.75866 30.0988L9.84886 20.5881C10.0497 19.9701 9.82968 19.2931 9.30395 18.9111L1.21371 13.0332C0.821861 12.7485 1.02324 12.1287 1.50761 12.1287H11.5077C12.1575 12.1287 12.7335 11.7102 12.9343 11.0922L16.0245 1.58156Z" fill="#FFD700" stroke="#FFD700"/>
                </svg>
                <svg className="size-7" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0245 1.58156C16.1741 1.1209 16.8259 1.1209 16.9755 1.58156L20.0657 11.0922C20.2665 11.7102 20.8425 12.1287 21.4923 12.1287H31.4924C31.9768 12.1287 32.1781 12.7485 31.7863 13.0332L23.696 18.9111C23.1703 19.2931 22.9503 19.9701 23.1511 20.5881L26.2413 30.0988C26.391 30.5594 25.8638 30.9425 25.4719 30.6578L17.3817 24.7799C16.8559 24.3979 16.1441 24.3979 15.6183 24.7799L7.52808 30.6578C7.13623 30.9425 6.60899 30.5594 6.75866 30.0988L9.84886 20.5881C10.0497 19.9701 9.82968 19.2931 9.30395 18.9111L1.21371 13.0332C0.821861 12.7485 1.02324 12.1287 1.50761 12.1287H11.5077C12.1575 12.1287 12.7335 11.7102 12.9343 11.0922L16.0245 1.58156Z" fill="#FFD700" stroke="#FFD700"/>
                </svg>
                <svg className="size-7" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0245 1.58156C16.1741 1.1209 16.8259 1.1209 16.9755 1.58156L20.0657 11.0922C20.2665 11.7102 20.8425 12.1287 21.4923 12.1287H31.4924C31.9768 12.1287 32.1781 12.7485 31.7863 13.0332L23.696 18.9111C23.1703 19.2931 22.9503 19.9701 23.1511 20.5881L26.2413 30.0988C26.391 30.5594 25.8638 30.9425 25.4719 30.6578L17.3817 24.7799C16.8559 24.3979 16.1441 24.3979 15.6183 24.7799L7.52808 30.6578C7.13623 30.9425 6.60899 30.5594 6.75866 30.0988L9.84886 20.5881C10.0497 19.9701 9.82968 19.2931 9.30395 18.9111L1.21371 13.0332C0.821861 12.7485 1.02324 12.1287 1.50761 12.1287H11.5077C12.1575 12.1287 12.7335 11.7102 12.9343 11.0922L16.0245 1.58156Z" fill="#FFD700" stroke="#FFD700"/>
                </svg>
                <svg className="size-7" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0245 1.58156C16.1741 1.1209 16.8259 1.1209 16.9755 1.58156L20.0657 11.0922C20.2665 11.7102 20.8425 12.1287 21.4923 12.1287H31.4924C31.9768 12.1287 32.1781 12.7485 31.7863 13.0332L23.696 18.9111C23.1703 19.2931 22.9503 19.9701 23.1511 20.5881L26.2413 30.0988C26.391 30.5594 25.8638 30.9425 25.4719 30.6578L17.3817 24.7799C16.8559 24.3979 16.1441 24.3979 15.6183 24.7799L7.52808 30.6578C7.13623 30.9425 6.60899 30.5594 6.75866 30.0988L9.84886 20.5881C10.0497 19.9701 9.82968 19.2931 9.30395 18.9111L1.21371 13.0332C0.821861 12.7485 1.02324 12.1287 1.50761 12.1287H11.5077C12.1575 12.1287 12.7335 11.7102 12.9343 11.0922L16.0245 1.58156Z" fill="#FFD700" stroke="#FFD700"/>
                </svg>
                <svg className="size-7" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0245 1.58156C16.1741 1.1209 16.8259 1.1209 16.9755 1.58156L20.0657 11.0922C20.2665 11.7102 20.8425 12.1287 21.4923 12.1287H31.4924C31.9768 12.1287 32.1781 12.7485 31.7863 13.0332L23.696 18.9111C23.1703 19.2931 22.9503 19.9701 23.1511 20.5881L26.2413 30.0988C26.391 30.5594 25.8638 30.9425 25.4719 30.6578L17.3817 24.7799C16.8559 24.3979 16.1441 24.3979 15.6183 24.7799L7.52808 30.6578C7.13623 30.9425 6.60899 30.5594 6.75866 30.0988L9.84886 20.5881C10.0497 19.9701 9.82968 19.2931 9.30395 18.9111L1.21371 13.0332C0.821861 12.7485 1.02324 12.1287 1.50761 12.1287H11.5077C12.1575 12.1287 12.7335 11.7102 12.9343 11.0922L16.0245 1.58156Z" fill="white" stroke="white"/>
                </svg>  
            </div>
        </>
    )
}

export default StarRating